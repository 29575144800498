import React, { useState } from "react";

import { resetPassword } from "services/adminServices";
import enhancer from "../../../enhancers/ResetPasswordEnhancer";
import { compose } from "redux";
import { connect } from "react-redux";
import NavigationActions from "redux/navigation/actions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { ForgotIcon } from "helper/constant";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Loader from "components/Loader";
import { Link } from "react-router-dom";

const { success, error } = NavigationActions;

const ResetPassword = props => {
  const { success, error } = props;
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount
  } = props;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const token = searchParams.get("token");
  const { token } = useParams()
  // console.log(token)
  const navigate = useNavigate();

  const handleResetPassword = async e => {
    const { values, isValid, handleSubmit } = props;
    e.preventDefault();
    handleSubmit();
    if (isValid) {
      var resetdata = {
        token: token,
        password: values.newpassword
      };
      setLoading(true);
      console.log(resetdata)
      await resetPassword(resetdata).then(data => {
        if (data.success) {
          setPasswordResetSuccess(true);
          success(data.message);
        } else {
          error(data.message);
        }
        setLoading(false);
      });
    }
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid">
      <div className="form-align">
        <div className="form-container login-form-container">
          <div className="login-icon">
            {/* <img src={ForgotIcon} alt="icon" height="100px" /> */}
          </div>

          {passwordResetSuccess ? (
            <>
              <div className="login-title">
                Your password has been successfully reset.
              </div>

              <div className=" pa-24">
                <button
                  type="submit"
                  className="btn form-button siteenterbutton"
                  onClick={() => navigate("/login")}
                >
                  Back to Login
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="login-title">Set New Password</div>
              <form className="pa-24" onSubmit={handleResetPassword}>
                <div className="form-group">
                  <label>
                    Password <span className="error-msg">*</span>
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type={togglePassword ? "text" : "password"}
                      className="form-control react-form-input"
                      id="newpassword"
                      onChange={handleChange}
                      value={values.newpassword}
                      onBlur={handleBlur}
                      placeholder="Password"
                    />
                    <div className="input-group-append">
                      {togglePassword ? (
                        <VisibilityOff
                          onClick={() => setTogglePassword(false)}
                        />
                      ) : (
                        <Visibility
                          onClick={() => {
                            setTogglePassword(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <Error field="newpassword" />
                </div>
                <div className="form-group">
                  <label>
                    Confirm Password <span className="error-msg">*</span>
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type={toggleConfirmPassword ? "text" : "password"}
                      className="form-control react-form-input"
                      id="confirmpassword"
                      onChange={handleChange}
                      value={values.confirmpassword}
                      onBlur={handleBlur}
                      placeholder="Confirm Password"
                    />
                    <div className="input-group-append">
                      {toggleConfirmPassword ? (
                        <VisibilityOff
                          onClick={() => setToggleConfirmPassword(false)}
                        />
                      ) : (
                        <Visibility
                          onClick={() => {
                            setToggleConfirmPassword(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <Error field="confirmpassword" />
                </div>
                <button
                  type="submit"
                  className="btn form-button siteenterbutton"
                  // disabled={isValid ? false : true}
                >
                  Reset Password
                </button>
                <div className="text-center mt-10">
                  <Link to="/login">Back to Login</Link>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default compose(
  enhancer,
  connect(null, { success, error })
)(ResetPassword);
