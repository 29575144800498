import React, { useEffect, useState } from "react";
// import { loginBack, iconDemo } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import NavigationActions from "redux/navigation/actions";
import AuthActions from "redux/auth/actions";
import { checkApi, loginApi } from "services/authServices";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import enhancer from "../../../enhancers/LoginFormEnhancer";
import Loader from "components/Loader";
import logo from "../../../assets/images/logo.svg";
// import nameIcon from "../../../assets/images/user-icon.svg";
import emailIcon from "../../../assets/images/message-icon.svg";
import lockIcon from "../../../assets/images/password-icon.svg";
import { useAsyncDebounce } from "react-table";
import { useDispatch } from "react-redux";

const { login, setUser, updateCredits } = AuthActions;

const {
  success,
  error,
  toggleOneTimeModal,
  fetching,
  toggleSubscriptionLoader,
} = NavigationActions;

const Login = (props) => {
  // console.log(props)
  const [togglePassword, setTogglePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    submitCount,
    isValid,
    token,
  } = props;
  const dispatch = useDispatch()
  let navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    handleSubmit();

    if (!isValid) {
      return;
    }

    fetching();
    setLoading(true);
    await loginApi(values).then((data) => {
      if (data.success) {
        if(data.user.role === 'admin'){
          success(data.message);
          props.login(data.token);
          props.setUser(data.user);
          navigate("/admin")
          return
        }
        console.log(data.user)
        success(data.message);
        props.login(data.token);
        // props.setUser(data.user.uid);
        props.setUser(data.user);
        dispatch(updateCredits(data.credits))
        console.log('Login successful, navigating to home page.');
        navigate("/");
        setLoading(false);
      } else {
        error(data.message);
        setLoading(false);
      }
    });
  };

  const checkLogin = async () => {
    fetching();

    await checkApi(token).then((data) => {
      if (data.success) {
        success();
        // props.history.push("/dashboard");
        navigate("/dashboard");
      } else {
        error();
      }
    });
  };

  // useEffect(() => {
  //     token !== null && checkLogin();
  // }, []);


  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="login-container">
      <div className="login-row">
        <div className="back-link">
          <Link to='/intro'><span>&lt;</span> Back</Link>
        </div>
        <div className="login-logo-data-box">
          <aside className="login-title">
            <h2>Welcome back!</h2>
          </aside>
        </div>
        <form className="login-form-main">
          <div className="form-group">
            <div className="login-input-box">
              <span>Email</span>
              <input
                type="email"
                className="form-control react-form-input"
                id="email"
                onChange={handleChange}
                value={values.email}
                onBlur={handleBlur}
                placeholder="name@email.com"
              />
            </div>
            {<Error field="email" />}
          </div>

          <div className="form-group">
            <div className="login-input-box">
              <span>Password</span>
              <input
                type={togglePassword ? "text" : "password"}
                className="form-control react-form-input"
                id="password"
                onChange={handleChange}
                value={values.password}
                onBlur={handleBlur}
                placeholder="Password"
              />
              <div
                className="input-group-append "
              >
                {togglePassword ? (
                  <VisibilityOff
                    style={{ fontSize: "20px" }}
                    onClick={() => setTogglePassword(false)}
                  />
                ) : (
                  <Visibility
                    style={{ fontSize: "20px" }}
                    onClick={() => {
                      setTogglePassword(true);
                    }}
                  />
                )}
              </div>
            </div>
            {<Error field="password" />}
          </div>
            <div
            className="link-label forgot-link"
            onClick={() => navigate("/forgotPassword")}
          >
            Forgot your password?
          </div>
          <button
            type="submit"
            className="login-btn"
            onClick={(e) => handleLogin(e)}
          >
            Login
          </button>
        </form>
      </div>
      <footer>
        &#169; 2024 Bandlr.com. All rights reserved.
      </footer>
      {loading && <Loader />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.accessToken,
  };
};

export default compose(
  enhancer,
  connect(mapStateToProps, {
    login,
    success,
    error,
    toggleOneTimeModal,
    toggleSubscriptionLoader,
    fetching,
    setUser,
    updateCredits
  })
)(Login);


