import React, { useEffect, useRef, useState } from "react";
import { compose } from "redux";
import { connect,useDispatch } from "react-redux";
import "./adminpanel.css";
import {
  createEventAdmin,
  fetchAllEvents as fetchEventsFromService,
  setStatusDown,
  updateEventAdmin,
  setStatusUp,
  fetchAllLocations,
  fetchAllCategories,
  createLocation,
} from "services/adminServices";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import AuthActions from "../../../redux/auth/actions";

const { success, error, logout } = AuthActions;
Modal.setAppElement("#root");

const AdminPanel = ({ token, role }) => {
  const [allEvents, setAllEvents] = useState([]);
  const [allLocations, setAllLocation] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const [eventForm, setEventForm] = useState({
    name: "",
    description: "",
    image: "",
    location: "",
    category: "",
    status: "up",
    importance: 0,
  });
  const fileInputRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingEventId, setEditingEventId] = useState(null);
  const [newLocation, setNewLocation] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (role !== "admin") {
      navigate("/");
      return;
    }
    const fetchAllEvents = async () => {
      try {
        const data = await fetchEventsFromService(token);
        if (data.success) {
          setAllEvents(data.data);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    const fetchLocations = async () => {
      const data = await fetchAllLocations(token);
      if (data.success) {
        setAllLocation(data.data.map((location) => location.name));
      } else {
        console.error(data.message);
      }
    };

    const fetchCategories = async () => {
      const data = await fetchAllCategories(token);
      if (data.success) {
        // setAllCategories(data.data.map((category) => category.name));
        setAllCategories(data.data);
      } else {
        console.error(data.message);
      }
    };

    fetchAllEvents();
    fetchLocations();
    fetchCategories();
  }, [token, role, navigate]);

  useEffect(() => {
    if (eventForm.image instanceof Blob) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(eventForm?.image);
    } else {
      setImagePreview(eventForm?.image?.url || "");
    }
  }, [eventForm.image]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventForm({ ...eventForm, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setEventForm({ ...eventForm, image: file });
  };

  const handleCreateEvent = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      console.log(eventForm.category);
      formData.append("name", eventForm.name);
      formData.append("description", eventForm.description);
      formData.append("location", eventForm.location);
      formData.append("category", eventForm.category);
      formData.append("status", eventForm.status);
      formData.append("importance", eventForm.importance);
      if (eventForm.image) {
        formData.append("image", eventForm.image);
      }
      // return;
      const data = await createEventAdmin(formData);
      if (data.success) {
        success("Successfully created event");
        setAllEvents([...allEvents, data.data]);
        setEventForm({
          name: "",
          description: "",
          image: null,
          location: "",
          category: "",
          status: "up",
          importance: 0,
        });
        setImagePreview("");
        if (fileInputRef.current) fileInputRef.current.value = "";
      } else {
        error("Failed to create an event");
        console.error("Creation failed:", data.message);
      }
    } catch (error) {
      console.error("Error creating event:", error);
    }
  };

  const handleUpdateEvent = async (e) => {
    e.preventDefault();
    try {
      console.log(eventForm.category)
      const formData = new FormData();
      formData.append("name", eventForm.name);
      formData.append("description", eventForm.description);
      formData.append("location", eventForm.location);
      formData.append("category", eventForm.category);
      formData.append("status", eventForm.status);
      formData.append("importance", eventForm.importance);
      if (eventForm.image) {
        formData.append("image", eventForm.image);
      }
      const data = await updateEventAdmin(editingEventId, formData);
      if (data.success) {
        setAllEvents(
          allEvents.map((event) =>
            event._id === editingEventId ? data.data : event
          )
        );
        success("Successfully updated event");
        setIsEditing(false);
        setEditingEventId(null);
        setImagePreview("");
        setEventForm({
          name: "",
          description: "",
          image: null,
          location: "",
          category: "",
          status: "up",
          importance: 0,
        });
        if (fileInputRef.current) fileInputRef.current.value = "";
      } else {
        error("Failed to update event");
        console.error("Update failed:", data.message);
      }
    } catch (error) {
      console.error("Error updating event:", error);
    }
  };

  const handleSetStatusDown = async (eventId) => {
    try {
      const data = await setStatusDown(eventId);
      if (data.success) {
        setAllEvents(
          allEvents.map((event) =>
            event._id === eventId ? { ...event, status: "down" } : event
          )
        );
      } else {
        console.error("Failed to update status:", data.message);
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleSetStatusUp = async (eventId) => {
    try {
      const data = await setStatusUp(eventId);
      if (data.success) {
        setAllEvents(
          allEvents.map((event) =>
            event._id === eventId ? { ...event, status: "up" } : event
          )
        );
      } else {
        console.error("Failed to update status:", data.message);
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleEdit = (event) => {
    console.log(event)
    setEventForm({
      name: event.name,
      description: event.description,
      image: event.image,
      location: event.location,
      category: event.category,
      status: event.status,
      importance: event.importance,
    });
    console.log(eventForm);
    setIsEditing(true);
    setEditingEventId(event._id);
    setImagePreview(event.image || "");
  };

  const handleOpenLocationModal = () => setIsLocationModalOpen(true);
  const handleCloseLocationModal = () => setIsLocationModalOpen(false);

  // const handleOpenCategoryModal = () => setIsCategoryModalOpen(true);
  // const handleCloseCategoryModal = () => setIsCategoryModalOpen(false);

  const handleOpenCategoryModal = () => navigate("/admin/categories");

  const handleAddLocation = async () => {
    console.log("Adding location");
    try {
      const data = await createLocation({ name: newLocation });
      if (data.success) {
        setAllLocation([...allLocations, newLocation]);
        setNewLocation("");
        handleCloseLocationModal();
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error("Error adding location:", error);
    }
  };

  const logoutUser = () => {
    dispatch(logout());
    navigate("/login");
    
  };


  const eventsToDisplay = allEvents.filter((event) => event.status === "up");
  const downEvents = allEvents.filter((event) => event.status === "down");
  console.log(eventForm)
  return (
    <div className="admin-panel-container">
      <div className="miscellanous-button-container">
        <div className="location-btn">
          <button onClick={handleOpenLocationModal}>+</button>
          Location
        </div>
        <div className="category-btn">
          <button onClick={handleOpenCategoryModal}>+</button>
          Category
        </div>
        <div className="logout-btn">
        <button className="" onClick={logoutUser}>
              Logout
            </button>
        </div>
      </div>

      {/* Location Modal */}
      <Modal
        isOpen={isLocationModalOpen}
        onRequestClose={handleCloseLocationModal}
        contentLabel="Add Location"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Add Location</h2>
        <input
          type="text"
          value={newLocation}
          onChange={(e) => setNewLocation(e.target.value)}
          placeholder="Enter new location"
        />
        <button onClick={handleAddLocation}>Add</button>
        <button onClick={handleCloseLocationModal}>Close</button>
      </Modal>

      <div className="event-form-container">
        <h2>{isEditing ? "Edit Event" : "Create Event"}</h2>
        <form onSubmit={isEditing ? handleUpdateEvent : handleCreateEvent}>
          <input
            type="text"
            name="name"
            value={eventForm.name}
            onChange={handleInputChange}
            placeholder="Event Name"
            required
          />
          <textarea
            name="description"
            value={eventForm.description}
            onChange={handleInputChange}
            placeholder="Event Description"
            required
          ></textarea>
          <label>
            Upload Event Image
            <input
              type="file"
              name="image"
              onChange={handleFileChange}
              accept=".jpg,.png,.jpeg,.svg"
              ref={fileInputRef}
            />
          </label>

          {imagePreview && (
            <img src={imagePreview} alt="Uploaded file preview" />
          )}

          <select
            name="location"
            value={eventForm.location}
            onChange={handleInputChange}
            required
          >
            <option value="" disabled>
              Select Location
            </option>
            {allLocations.map((location) => (
              <option key={location} value={location}>
                {location}
              </option>
            ))}
          </select>

          <select
            name="category"
            value={eventForm.category}
            onChange={handleInputChange}
            required
          >
            <option value="" disabled>
              Select Category
            </option>
            {allCategories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </select>

          <select
            name="status"
            value={eventForm.status}
            onChange={handleInputChange}
            required
          >
            <option value="up">Active</option>
            <option value="down">Inactive</option>
          </select>

          <label>
            Importance
            <input
              type="number"
              name="importance"
              value={eventForm.importance}
              onChange={handleInputChange}
              min="0"
            />
          </label>

          <button type="submit">
            {isEditing ? "Update Event" : "Create Event"}
          </button>
        </form>
      </div>
      <div className="events-container">
        <h2>Activated Events</h2>
        <div className="events-list">
          {eventsToDisplay.map((event) => (
            <div key={event._id} className="event-card">
              <img
                src={event.image.url || "/changes/no_event.png"}
                alt={event.name}
              />
              <h3>{event.name}</h3>
              <p>{event.description}</p>
              <div className="event-card-actions">
                <button onClick={() => handleEdit(event)}>Edit</button>
                <button onClick={() => handleSetStatusDown(event._id)}>
                  Deactivate
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="down-events-container">
        <h2>Deactivated Events</h2>
        <div className="events-list">
          {downEvents.map((event) => (
            <div key={event._id} className="event-card">
              <img
                src={event.image.url || "/changes/no_event.png"}
                alt={event.name}
              />
              <h3>{event.name}</h3>
              <p>{event.description}</p>
              <div className="event-card-actions">
                <button onClick={() => handleEdit(event)}>Edit</button>
                <button onClick={() => handleSetStatusUp(event._id)}>
                  Activate
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.user.token,
  role: state.auth.user.role,
});

export default compose(connect(mapStateToProps, { success, error, logout }))(
  AdminPanel
);
