import { api, handleResponse, handleError } from "./apiServices";

export const forgotPassword = data =>
  api()
    .post("/api/auth/forgotPassword", data)
    .then(handleResponse)
    .catch(handleError);

export const resetPassword = data =>
  api()
    .post(`/api/auth/changeForgotPassword`, data)
    .then(handleResponse)
    .catch(handleError);

export const fetchAllEvents = token => 
  api()
    .get('/api/admin/allEvents')
    .then(handleResponse)
    .catch(handleError)

export const fetchAllLocations = token =>
  api()
    .get('/api/admin/allLocations')
    .then(handleResponse)
    .catch(handleError)

export const fetchAllCategories = () =>
  api()
    .get('/api/admin/allCategories')
    .then(handleResponse)
    .catch(handleError)

export const createCategory = data =>
  api()
    .post('/api/admin/createCategory', data)
    .then(handleResponse)
    .catch(handleError)

export const updateCategory = (data,category) =>
  api()
    .patch(`/api/admin/updateCategory/${category.name}`, data)
    .then(handleResponse)
    .catch(handleError)

export const createLocation = data =>
  api()
    .post('/api/admin/createLocation', data)
    .then(handleResponse)
    .catch(handleError)

export const createEventAdmin = data =>
  api()
    .post('/api/admin/createEventAdmin', data)
    .then(handleResponse)
    .catch(handleError)

export const updateEventAdmin = (eventId, updatedData) =>
  api()
    .put(`/api/admin/updateEventAdmin/${eventId}`, updatedData)
    .then(handleResponse)
    .catch(handleError)


export const setStatusDown = data =>
  api()
    .patch(`/api/admin/setStatusDown/${data}`, data)
    .then(handleResponse)
    .catch(handleError)


export const setStatusUp = data =>
  api()
    .patch(`/api/admin/setStatusUp/${data}`, data)
    .then(handleResponse)
    .catch(handleError)