// import React, { useState } from "react";
// import { compose } from "redux";
// import { useNavigate } from "react-router-dom";
// import { registerApi } from "../../../services/authServices";
// import enhancer from "../../../enhancers/RegisterFormEnhancer";
// import AuthActions from "../../../redux/auth/actions";
// import Loader from "components/Loader";
// import { useDispatch } from "react-redux";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import Visibility from "@mui/icons-material/Visibility";
// import logo from "../../../assets/images/logo.svg";
// import nameIcon from "../../../assets/images/user-icon.svg";
// import emailIcon from "../../../assets/images/message-icon.svg";
// import lockIcon from "../../../assets/images/password-icon.svg";

// const { success, error, login, setUser, updateCredits } = AuthActions;

// const Register = (props) => {
//   const navigate = useNavigate();
//   const [loader, setLoader] = useState(false);
//   const [togglePassword, setTogglePassword] = useState(false);
//   const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
//   const dispatch = useDispatch();

//   let { values, isValid, handleSubmit } = props;

//   const handleRegister = async (e) => {
//     e.preventDefault();
//     handleSubmit();

//     if (!isValid) {
//       return;
//     }

//     const data = {
//       name: values.name,
//       email: values.email,
//       password: values.password,
//       confirmPassword: values.confirm_password,
//     };

//     setLoader(true);
//     const result = await registerApi(data);
//     if (result.success) {
//       success(result.message);
//       setLoader(false);
//       dispatch(login(result.data.token));
//       dispatch(setUser(result.data));
//       dispatch(updateCredits(result.data.credits));
//       navigate("/");
//     } else {
//       error(result.message);
//       setLoader(false);
//     }
//   };

//   const { handleChange, handleBlur, errors, touched, submitCount } = props;

//   const Error = (props) => {
//     const field1 = props.field;
//     if ((errors[field1] && touched[field1]) || submitCount > 0) {
//       return (
//         <span className={props.class ? props.class : "error-msg"}>
//           {errors[field1]}
//         </span>
//       );
//     } else {
//       return <span />;
//     }
//   };

//   return (
//     <>
//       {loader ? (
//         <Loader />
//       ) : (
//         <div className="login-container">
//           <div className="login-row">
//             <div className="login-logo-data-box">
//               <img src={logo} alt="" />
//               <aside className="login-title">
//                 Let’s Get Started<span>Create an new account</span>
//               </aside>
//             </div>
//             <form className="login-form-main">
//               <div className="form-group">
//                 <div className="login-input-box">
//                   <img src={nameIcon} alt="" className="login-input-icon" />
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="name"
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     placeholder="Name"
//                   />
//                 </div>
//                 <Error field="name" />
//               </div>

//               <div className="form-group">
//                 <div className="login-input-box">
//                   <img src={emailIcon} alt="" className="login-input-icon" />
//                   <input
//                     type="email"
//                     className="form-control"
//                     id="email"
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     placeholder="Email"
//                   />
//                 </div>
//                 <Error field="email" />
//               </div>

//               <div className="form-group">
//                 <div className="input-group login-input-box">
//                   <img src={lockIcon} alt="" className="login-input-icon" />
//                   <input
//                     type={togglePassword ? "text" : "password"}
//                     className="form-control"
//                     id="password"
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     placeholder="Create Password"
//                   />
//                   <div className="input-group-append">
//                     {togglePassword ? (
//                       <VisibilityOff
//                         style={{ fontSize: "20px" }}
//                         onClick={() => setTogglePassword(false)}
//                       />
//                     ) : (
//                       <Visibility
//                         style={{ fontSize: "20px" }}
//                         onClick={() => setTogglePassword(true)}
//                       />
//                     )}
//                   </div>
//                 </div>
//                 <Error field="password" />
//               </div>

//               <div className="form-group">
//                 <div className="input-group login-input-box">
//                   <img src={lockIcon} alt="" className="login-input-icon" />
//                   <input
//                     type={toggleConfirmPassword ? "text" : "password"}
//                     className="form-control"
//                     id="confirm_password"
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     placeholder="Confirm Password"
//                   />
//                   <div className="input-group-append">
//                     {toggleConfirmPassword ? (
//                       <VisibilityOff
//                         style={{ fontSize: "20px" }}
//                         onClick={() => setToggleConfirmPassword(false)}
//                       />
//                     ) : (
//                       <Visibility
//                         style={{ fontSize: "20px" }}
//                         onClick={() => setToggleConfirmPassword(true)}
//                       />
//                     )}
//                   </div>
//                 </div>
//                 <Error field="confirm_password" />
//               </div>

//               <button
//                 type="submit"
//                 onClick={handleRegister}
//                 className="login-btn"
//               >
//                 Register
//               </button>
//               <div className="account-text">
//                 Have an account?{" "}
//                 <span onClick={() => navigate("/login")}>Sign In</span>
//               </div>
//             </form>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default compose(enhancer)(Register);

























import React, { useState } from "react";
import { compose } from "redux";
import { Link, useNavigate } from "react-router-dom";
import { registerApi } from "../../../services/authServices";
import enhancer from "../../../enhancers/RegisterFormEnhancer";
import AuthActions from "../../../redux/auth/actions";
import Loader from "components/Loader";
import { useDispatch } from "react-redux";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import logo from "../../../assets/images/logo.svg";
import nameIcon from "../../../assets/images/user-icon.svg";
import emailIcon from "../../../assets/images/message-icon.svg";
import lockIcon from "../../../assets/images/password-icon.svg";

const { success, error, login, setUser, updateCredits } = AuthActions;

const Register = (props) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
  const dispatch = useDispatch();

  let { values, isValid, handleSubmit } = props;

  const handleRegister = async (e) => {
    e.preventDefault();
    handleSubmit();

    if (!isValid) {
      return;
    }

    const data = {
      name: values.name,
      email: values.email,
      password: values.password,
      confirmPassword: values.confirm_password,
    };

    setLoader(true);
    const result = await registerApi(data);
    if (result.success) {
      success(result.message);
      setLoader(false);
      dispatch(login(result.data.token));
      dispatch(setUser(result.data));
      dispatch(updateCredits(result.data.credits));
      navigate("/");
    } else {
      error(result.message);
      setLoader(false);
    }
  };

  const { handleChange, handleBlur, errors, touched, submitCount } = props;

  const Error = (props) => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <>
      {loader ? (
        
        <Loader />
      ) : (
        <div className="login-container">
          <div className="login-row">
            <div className="back-link">
              <span>&lt;</span><Link to='/intro'> Back</Link>
            </div>
            <div className="login-logo-data-box">
              <aside className="login-title">
                <h2>Create an account</h2>
              </aside>
            </div>
            <form className="login-form-main">
              <div className="form-group">
                <div className="login-input-box">
                  <span className="field-title">Name</span>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Name"
                  />
                </div>
                <Error field="name" />
              </div>

              <div className="form-group">
                <div className="login-input-box">
                <span className="field-title">Email</span>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Email"
                  />
                </div>
                <Error field="email" />
              </div>

              <div className="form-group">
                <div className="login-input-box">
                <span className="field-title">Password</span>
                  <input
                    type={togglePassword ? "text" : "password"}
                    className="form-control"
                    id="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Minimum 8 characters"
                  />
                  <div className="input-group-append">
                    {togglePassword ? (
                      <VisibilityOff
                        style={{ fontSize: "20px" }}
                        onClick={() => setTogglePassword(false)}
                      />
                    ) : (
                      <Visibility
                        style={{ fontSize: "20px" }}
                        onClick={() => setTogglePassword(true)}
                      />
                    )}
                  </div>
                </div>
                <Error field="password" />
              </div>

              <div className="form-group">
                <div className="login-input-box">
                <span className="field-title">Confirm Password</span>
                  <input
                    type={toggleConfirmPassword ? "text" : "password"}
                    className="form-control"
                    id="confirm_password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Minimum 8 characters"
                  />
                  <div className="input-group-append">
                    {toggleConfirmPassword ? (
                      <VisibilityOff
                        style={{ fontSize: "20px" }}
                        onClick={() => setToggleConfirmPassword(false)}
                      />
                    ) : (
                      <Visibility
                        style={{ fontSize: "20px" }}
                        onClick={() => setToggleConfirmPassword(true)}
                      />
                    )}
                  </div>
                </div>
                <Error field="confirm_password" />
              </div>
              <button
                type="submit"
                onClick={handleRegister}
                className="login-btn"
              >
                Register
              </button>
            </form>
          </div>
          <footer>
          &#169; 2024 Bandlr.com. All rights reserved.
          </footer>
        </div>
      )}
    </>
  );
};

export default compose(enhancer)(Register);

