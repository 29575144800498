const homeActions = {
  SET_EVENTS: "SET_EVENTS",
  SET_USER_EVENTS: "SET_USER_EVENTS",

  setEvents: (events) => {
    const timestamp = Date.now();
    console.log("SET_EVENTS fucntion homeActions", events);
    sessionStorage.setItem("events", JSON.stringify(events)); // Changed to sessionStorage
    sessionStorage.setItem("eventsTimestamp", timestamp);
    return {
      type: homeActions.SET_EVENTS,
      payload: {
        events: events,
        timestamp: timestamp,
      },
    };
  },
  setUserEvents: (events) => {
    console.log("SET_USER_EVENTS fucntion homeActions", events);
    localStorage.setItem("userEvents", JSON.stringify(events));

    return {
      type: homeActions.SET_USER_EVENTS,
      payload: {
        events: events,
      },
    };
  },
};

export default homeActions;
