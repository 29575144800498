import React from "react";

function DropDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      fill="none"
      viewBox="0 0 18 19"
    >
      <path
        // stroke="#000"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4.5 7.25l4.5 4.5 4.5-4.5"
      ></path>
    </svg>
  );
}

export default DropDownIcon;