// import {
//   Box,
//   Typography,
//   Menu,
//   MenuItem,
//   Avatar,
//   IconButton,
// } from "@mui/material";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import moment from "moment";
// import React, { useState } from "react";
// import { memo } from "react";
// import { useNavigate } from "react-router-dom";

// function MessageComponent({ user, message, onReply }) {
//   const sameSender = message?.userId === user?.userId;
//   const [anchorEl, setAnchorEl] = useState(null);
//   const navigate = useNavigate()

//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const handleReply = () => {
//     onReply(message);
//     handleClose();
//   };

//   const handleNameClick = (message) => {
//     console.log(message?.userId)
//     navigate(`/userProfile/${message?.userId}`);
//   }

//   return (
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: sameSender ? "row-reverse" : "row",
//         alignItems: "flex-start",
//         marginBottom: "0.5rem",
//         position: "relative",
//       }}
//     >
//       {!sameSender && (
//         <Avatar
//           src={message?.profileImage}
//           alt={message?.userInfo?.name}
//           sx={{
//             marginRight: sameSender ? 0 : "0.5rem",
//             marginLeft: sameSender ? "0.5rem" : 0,
//           }}
//         />
//       )}
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: sameSender ? "row-reverse" : "row",
//           alignItems: "center",
//           width: "100%",
//         }}
//       >
//         <Box
//           sx={{
//             backgroundColor: sameSender ? "#DCF8C6" : "#f7f7f7",
//             color: "black",
//             borderRadius: sameSender ? "25px 0 25px 25px" : "0 25px 25px 25px",
//             padding: { xs: "0.6rem", sm: "0.8rem" },
//             width: "fit-content",
//             border: "1px solid #ccc",
//             marginBottom: "0.5rem",
//             maxWidth: { xs: "80%", sm: "60%" },
//             fontFamily: "Open Sans, sans-serif",
//             boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
//             display: "flex",
//             flexDirection: "column",
//             position: "relative",
//           }}
//         >
//           {!sameSender && (
//             <Typography
//               color={"#0000007a"}
//               fontWeight={"100"}
//               onClick={() => handleNameClick(message)}
//               sx={{
//                 fontSize: { xs: "0.6rem", sm: "1rem" },
//                 marginBottom: "4px",
//                 cursor: "pointer",
//                 '&:hover': {
//                   textDecoration: "underline",
//                   color: "darkblue",
//                 },
//               }}
//             >
//               {"~ " + message?.userInfo?.name}
//             </Typography>
//           )}
//           {message.replyTo && (
//             <Box
//               sx={{
//                 backgroundColor: "#e5e5e5",
//                 padding: "0.5rem",
//                 borderRadius: "5px",
//                 marginBottom: "0.5rem",
//                 fontStyle: "italic",
//                 borderLeft: "3px solid #007bff",
//                 paddingLeft: "10px",
//               }}
//             >
//               <Typography variant="body2" sx={{ fontWeight: "bold" }}>
//                 {message?.replyTo?.userId?._id === user?.userId
//                   ? "Me"
//                   : message?.replyTo?.userId?.name}
//               </Typography>
//               <Typography variant="body2" sx={{wordBreak: "break-word",}}>
//                 {message.replyTo?.content}
//               </Typography>
//             </Box>
//           )}
//           {message.message && (
//             <Typography
//               sx={{
//                 lineHeight: "1.4",
//                 textAlign: "left",
//                 letterSpacing: "1px",
//                 color: "#333",
//                 fontFamily: "Roboto, sans-serif",
//                 wordBreak: "break-word",
//               }}
//             >
//               {message.message}
//             </Typography>
//           )}
//           <Typography
//             color={"text.secondary"}
//             display="block"
//             textAlign={sameSender ? "right" : "left"}
//             sx={{ fontSize: { xs: "0.5rem", sm: "0.75rem" }, marginTop: "4px" }}
//           >
//             {moment(message.createdAt).fromNow()}
//           </Typography>
//         </Box>
//         <IconButton
//           aria-label="more"
//           aria-controls="long-menu"
//           aria-haspopup="true"
//           onClick={handleMenuClick}
//           sx={{
//             padding: "0.5rem",
//             position: "relative",
//             marginLeft: sameSender ? "0" : "0.5rem",
//             marginRight: sameSender ? "0.5rem" : "0",
//           }}
//         >
//           <MoreVertIcon />
//         </IconButton>
//       </Box>
//       <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
//         <MenuItem onClick={handleReply}>Reply</MenuItem>
//       </Menu>
//     </Box>
//   );
// }

// export default memo(MessageComponent);








import {
  Box,
  Typography,
  Menu,
  MenuItem,
  Avatar,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import React, { useState } from "react";
import { memo } from "react";
import { useNavigate } from "react-router-dom";

function MessageComponent({ user, message, onReply, pinMessage, unpinMessage, isPinned, setPinnedMessages }) {
  const sameSender = message?.userId === user?.userId;
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate()

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReply = () => {
    onReply(message);
    handleClose();
  };

  const handleNameClick = (message) => {
    console.log(message?.userId)
    navigate(`/userProfile/${message?.userId}`);
  }
  // console.log(message)
  const handlePin = () => {
    if (!isPinned) {
      pinMessage(message?.msgId);
      // setPinnedMessages((prev)=>{console.log(prev)});
    } else {
      unpinMessage(message?.msgId);
    }
    handleClose();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: sameSender ? "row-reverse" : "row",
        alignItems: "flex-start",
        marginBottom: "0.5rem",
        position: "relative",
      }}
    >
      {!sameSender && (
        <Avatar
          src={message?.profileImage?.url}
          alt={message?.userInfo?.name}
          sx={{
            marginRight: sameSender ? 0 : "0.5rem",
            marginLeft: sameSender ? "0.5rem" : 0,
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: sameSender ? "row-reverse" : "row",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: sameSender ? "#F3F3F3" : "#F3F3F3",
            color: "black",
            borderRadius: sameSender ? "25px 0 25px 25px" : "0 25px 25px 25px",
            padding: { xs: "0.6rem", sm: "0.8rem" },
            width: "fit-content",
            // border: "1px solid #ccc",
            marginBottom: "0.5rem",
            maxWidth: { xs: "80%", sm: "60%" },
            fontFamily: "Open Sans, sans-serif",
            // boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          {!sameSender && (
            <Typography
              color={"#0000007a"}
              fontWeight={"100"}
              onClick={() => handleNameClick(message)}
              sx={{
                fontSize: { xs: "0.9rem", sm: "1rem" },
                marginBottom: "4px",
                cursor: "pointer",
                '&:hover': {
                  textDecoration: "underline",
                  color: "darkblue",
                },
              }}
            >
              {"~ " + message?.userInfo?.name}
            </Typography>
          )}
          {message.replyTo && (
            <Box
              sx={{
                // backgroundColor: "#e5e5e5",
                backgroundColor: "#e5e5e5",
                padding: "0.5rem",
                borderRadius: "5px",
                marginBottom: "0.5rem",
                fontStyle: "italic",
                borderLeft: "3px solid black",
                paddingLeft: "10px",
                fontSize: { xs: "0.75rem", sm: "0.75rem" }
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {message?.replyTo?.userId?._id === user?.userId
                  ? "Me"
                  : message?.replyTo?.userId?.name}
              </Typography>
              <Typography variant="body2" sx={{wordBreak: "break-word",fontSize: { xs: "0.75rem", sm: "0.75rem" }}}>
                {message.replyTo?.content}
              </Typography>
            </Box>
          )}
          {message.message && (
            <Typography
              sx={{
                lineHeight: "1.4",
                textAlign: "left",
                letterSpacing: "1px",
                color: "#333",
                fontFamily: "Roboto, sans-serif",
                wordBreak: "break-word",
                background:"#F3F3F3",
                fontSize: { xs: "0.75rem", sm: "0.75rem" }
              }}
            >
              {message.message}
            </Typography>
          )}
          <Typography
            color={"text.secondary"}
            display="block"
            textAlign={sameSender ? "right" : "left"}
            sx={{ fontSize: { xs: "0.5rem", sm: "0.75rem" }, marginTop: "4px" }}
          >
            {moment(message.createdAt).fromNow()}
          </Typography>
        </Box>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleMenuClick}
          sx={{
            padding: "0.5rem",
            position: "relative",
            marginLeft: sameSender ? "0" : "0.5rem",
            marginRight: sameSender ? "0.5rem" : "0",
          }}
        >
          <MoreVertIcon />
        </IconButton>
      </Box>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleReply}>Reply</MenuItem>
        <MenuItem onClick={handlePin}>{isPinned ? 'Unpin' : 'Pin'}</MenuItem>
      </Menu>
    </Box>
  );
}

export default memo(MessageComponent);