import React, { Fragment, useEffect } from "react";
import {
  Router,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  RouterProvider,
  Routes
} from "react-router-dom";
import { ThemeProvider } from "styled-components";

// Redux store provider
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
// Style Root for making media queries to inline css
import { StyleRoot } from "radium";
// Layout Routes
import indexRoutes from "./routes/index";
// import layoutRoutes from "./routes/index.jsx";
import { PersistGate } from "redux-persist/integration/react";
// import "./assets/scss/app.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "./assets/css/custom.css";
import MainRoutes from "./routes/Routes";
import ReactGA from "react-ga4";
const TRACKING_ID = "G-DV2H1TT9ED";

// import "pretty-checkbox/src/pretty-checkbox.scss";

// const currentAppLocale =
//   AppLocale[getCurrentLanguage(config.defaultLanguage || "english").locale];



const App = props => {

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });
  }, [])
  
  return (
    <Fragment>
      {/* <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
      > */}
      {/* <ThemeProvider > */}
      {/* <StyleRoot> */}
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <MainRoutes />
          </BrowserRouter>

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          {/*End layout routes */}
        </PersistGate>
      </Provider>
      {/* </StyleRoot> */}
      {/* </ThemeProvider> */}
      {/* </IntlProvider> */}
    </Fragment>
  );
};

export default App;

// If you want to choose different color schema go to settings/index.jsx and set your theme and language.

// If you want to change sidebar nav list then go to util/data/sidebar.jsx
