import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import AuthActions from "redux/auth/actions";
import { compose } from "redux";
import {
  getAllEvents,
  addUserToGroup,
  getEvents
} from "services/dashboardServices";
import "./home.css";
import LocationIcon from "./LocationIcon";
import DropDownIcon from "./DropDownIcon";
import { fetchAllCategories, fetchAllLocations } from "services/adminServices";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  EffectCoverflow,
  Pagination,
  Scrollbar,
  A11y
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import homeActions from "redux/home/action";
import SkeletonCard from "./SkeletonEventCard";
import EventCard from "./EventCard";

const { success, error, updateCredits } = AuthActions;
const { setEvents, setUserEvents } = homeActions;

const Home = props => {
  const { token, userInfo, role } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [allEvents, setAllEvents] = useState([]);
  const [city, setCity] = useState("New York"); // Default city
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [locations, setLocations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [expandedEvents, setExpandedEvents] = useState({});
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [loading, setLoading] = useState(true);

  const freshnessDuration = 3600000;
  // const freshnessDuration = 36;
  // const sessionStorageKey = "lastFetchTimestamp";

  const eventsTimestamp = useSelector(state => state.home.eventsTimestamp);

  const events = useSelector(state => state.home.events);
  const userEvents = useSelector(state => state.home.userEvents);

  useEffect(() => {
    const [navigationEntry] = performance.getEntriesByType("navigation");
    const isReload = navigationEntry
      ? navigationEntry.type === "reload"
      : false;
    // console.log("isReload:", isReload);

    const now = Date.now();
    const shouldFetchEvents =
      !eventsTimestamp || now - eventsTimestamp > freshnessDuration;

    let isMounted = true;
    if (shouldFetchEvents) {
      const getAllUserEvents = async () => {
        try {
          let data;
          if (token) {
            data = await getAllEvents(token);
          } else {
            data = await getEvents();
          }
          if (isMounted) {
            if (data.success) {
              // setAllEvents(data.data);
              dispatch(setEvents(data.data));
              dispatch(
                setUserEvents(data.userEvents?.map(e => e.eventId.toString()))
              );
              // sessionStorage.setItem(sessionStorageKey, now.toString());
            } else {
              dispatch(error("Something Went Wrong"));
            }
          }
        } catch (error) {
          console.error("Error fetching events:", error);
          dispatch(error("Something Went Wrong"));
        }
      };
      getAllUserEvents().then(() => setLoading(false));
    } else {
      setLoading(false);
    }
    return () => {
      isMounted = false;
    };
  }, [token, dispatch, eventsTimestamp, freshnessDuration]);

  useEffect(() => {
    if (role === "admin") {
      navigate("/admin");
      return;
    }
    // let isMounted = true;

    // const getAllUserEvents = async () => {
    //   try {
    //     let data;
    //     if (token) {
    //       data = await getAllEvents(token);
    //     } else {
    //       data = await getEvents();
    //     }
    //     if (isMounted) {
    //       if (data.success) {
    //         // setAllEvents(data.data);
    //         dispatch(setEvents(data.data));
    //         dispatch(
    //           setUserEvents(data.userEvents?.map((e) => e.eventId.toString()))
    //         );
    //       } else {
    //         dispatch(error("Something Went Wrong"));
    //       }
    //     }
    //   } catch (error) {
    //     console.error("Error fetching events:", error);
    //     dispatch(error("Something Went Wrong"));
    //   }
    // };

    const fetchLocations = async () => {
      try {
        const data = await fetchAllLocations(token);
        if (data.success) {
          setLocations(data.data.map(location => location.name));
        }
      } catch (err) {
        console.error("Error fetching locations:", err);
      }
    };

    const fetchCategories = async () => {
      try {
        const data = await fetchAllCategories();
        if (data.success) {
          setCategories(data.data.map(category => category?.name));
        }
      } catch (err) {
        console.error("Error fetching categories:", err);
      }
    };
    fetchCategories();
    fetchLocations();
    // getAllUserEvents().then(() => setLoading(false));

    // return () => {
    //   isMounted = false;
    // };
  }, [token, dispatch]);

  const openModal = event => {
    if (!token) {
      navigate("/intro");
    } else {
      addUser(event);
    }
  };

  const addUser = async event => {
    if (!event) return;

    const addObj = {
      userId: userInfo.userId,
      groupId: event._id
    };
    const data = await addUserToGroup(token, addObj);
    if (data.success) {
      dispatch(updateCredits(data.credits));
      navigate(`/chat/${event._id}`);
    } else {
      dispatch(error(data.message));
      console.log("Failed to add user");
    }
  };

  const handleCityChange = selectedCity => {
    setCity(selectedCity);
    setIsDropdownOpen(false);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredEvents = events?.filter(event => {
    return event.location === city;
  });

  const categorizeEvents = () => {
    const categorized = {};
    categories.forEach(category => {
      categorized[category] = [];
    });
    filteredEvents.forEach(event => {
      if (event.status === "up" && categorized[event?.category?.name]) {
        categorized[event?.category?.name].push(event);
      }
    });
    return categorized;
  };

  const truncateText = (text, maxLength, isExpanded) => {
    if (isExpanded) {
      return text;
    }
    if (text.length > maxLength) {
      return text.substring(0, maxLength);
    }
    return text;
  };

  const toggleExpandTitle = eventId => {
    setExpandedEvents(prevState => ({
      ...prevState,
      [eventId]: !prevState[eventId]
    }));
  };

  // Function to toggle event description expansion
  const toggleExpandDescription = eventId => {
    setExpandedDescriptions(prevState => ({
      ...prevState,
      [eventId]: !prevState[eventId]
    }));
  };

  // creating field for profilePicImageStack
  events.forEach(event => {
    const profilePicStack = event.users.filter(
      user => user.profileImage.public_id !== ""
    );
    event.profilePicStack = profilePicStack.slice(0, 3); // Limit to 5 users
  });

  // console.log(events);

  return (
    <div className="home-container">
      <div className="home-title">Join a group & plan a meetup!</div>
      <header className="header">
        <div>
          <LocationIcon />
        </div>
        <div className="dropdown" ref={dropdownRef}>
          <div className="dropdown-header" onClick={handleDropdownToggle}>
            {city}
            <DropDownIcon />
          </div>
          {isDropdownOpen && (
            <div className="dropdown-menu">
              {locations.map((loc, index) => (
                <div
                  key={index}
                  className="dropdown-item"
                  onClick={() => handleCityChange(loc)}
                >
                  {loc}
                </div>
              ))}
            </div>
          )}
        </div>
      </header>
      <div className="events-container">
        {Object.entries(categorizeEvents()).map(
          ([category, events]) =>
            events.length > 0 && (
              <div className="category" key={category}>
                <h2 className="category-txt">{category}</h2>
                <Swiper
                  // install Swiper modules
                  modules={[
                    Navigation,
                    Pagination,
                    Scrollbar,
                    A11y,
                    EffectCoverflow
                  ]}
                  spaceBetween={80}
                  touchRatio={2}
                  // momentumRatio={1.5}
                  slidesPerView={2.5}
                  centeredSlides={false}
                  // slidesPerView={'auto'}
                  // slidesPerView={"auto"}
                  scrollbar={{ draggable: false, hide: true }}
                  pagination={{ clickable: true, dynamicBullets: true }}
                  // onSwiper={(swiper) => console.log(swiper)}
                  onSlideChange={() => console.log("slide change")}
                  className="mySwiper"
                  // grabCursor={true}
                  freeMode={true}
                  breakpoints={{
                    640: {
                      slidesPerView: 4,
                      spaceBetween: 10
                    },
                    768: {
                      slidesPerView: 5,
                      spaceBetween: 40
                    },
                    1024: {
                      slidesPerView: 6,
                      spaceBetween: 50
                    }
                  }}
                >
                  {events.map(
                    (e, i) =>
                      e.status === "up" && (
                        <SwiperSlide key={e._id}>
                          {loading ? (
                            <SkeletonCard key={i} />
                          ) : (
                            <EventCard
                              event={e}
                              expandedEvents={expandedEvents}
                              expandedDescriptions={expandedDescriptions}
                              toggleExpandTitle={toggleExpandTitle}
                              toggleExpandDescription={toggleExpandDescription}
                              loading={loading}
                              navigate={navigate}
                              userEvents={userEvents}
                              openModal={openModal}
                              truncateText={truncateText}
                            />
                          )}
                        </SwiperSlide>
                      )
                  )}
                  <SwiperSlide></SwiperSlide>
                </Swiper>
              </div>
            )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    token: state.auth.accessToken,
    userInfo: state.auth.user,
    // credits: state.auth.credits,
    role: state.auth.user.role
  };
};

export default compose(
  connect(mapStateToProps, { success, error, updateCredits })
)(Home);
