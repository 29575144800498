import React from "react";

function ProfileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        // stroke="#000"
        stroke="#fff"
        // stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20.5 21v-2a4 4 0 00-4-4h-8a4 4 0 00-4 4v2M12.5 11a4 4 0 100-8 4 4 0 000 8z"
      ></path>
    </svg>
  );
}

export default ProfileIcon;