import homeActions from "./action";

const initialState = {
  events: sessionStorage.getItem("events")
    ? JSON.parse(sessionStorage.getItem("events"))
    : [],
  eventsTimestamp: sessionStorage.getItem("eventsTimestamp")
    ? parseInt(sessionStorage.getItem("eventsTimestamp"), 10)
    : 0,
  userEvents: sessionStorage.getItem("userEvents")
    ? JSON.parse(sessionStorage.getItem("userEvents"))
    : [],
};

export default function rootReducer(state = initialState, action) {
  console.log("home Reducer");

  switch (action.type) {
    case homeActions.SET_EVENTS:
      return {
        ...state,
        events: action.payload.events,
        eventsTimestamp: action.payload.timestamp,
      };
    case homeActions.SET_USER_EVENTS:
      return {
        ...state,
        userEvents: action.payload.events,
      };
    default:
      return state;
  }
}
